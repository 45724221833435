// Angular
import { Component, OnInit, ViewChild, ElementRef, booleanAttribute } from '@angular/core';

// Material
import { MatSlideToggleChange as MatSlideToggleChange } from '@angular/material/slide-toggle';

// Shared Lib
import { LoggingService, NotificationBarService, LoadingBarService, CloudApiResponse, sessionStorageItem } from 'kscigcorelib';
import { ApplicationConfig, ApplicationConfigViewModel, CustomerLanguagePreference, SessionService, EncryptionService } from 'kscigcorelib';

// Application Core
import { SessionHelper } from '../../shared/helpers/session.helper';

// Component
import { NotifyGeneralService } from './notify-general.service';
import { NotifyMessagesService } from '../notify-messages/notify-messages.service';
import { MessageCategory, Message } from 'src/app/notify/notify-model';

@Component({
  selector: 'app-notify-general',
  templateUrl: './notify-general.component.html',
  styleUrls: ['./notify-general.component.css']
})
export class NotifyGeneralComponent implements OnInit {

  public customerId:string;
  public customerNotifyConfigs:ApplicationConfig[] = []; 
  public customerNotifyConfigsViewModel:ApplicationConfigViewModel[] = [];
  public customerNotifyConfig: ApplicationConfig = new ApplicationConfig();
  public languagePreferences : CustomerLanguagePreference[] = [];  
  public gIsEmailEnabled: boolean = false;
  public gIsSMSEnabled: boolean = false;
  public gIsTranslatorEnabled: boolean = false;
  public isSMSOptInEnabled:boolean = false;

  public isWelcomeTextEnabledConfig: ApplicationConfig = new ApplicationConfig();
  public isPreOpMessageEnabledConfig: ApplicationConfig = new ApplicationConfig();
  public hrsPriorToSurgeryConfig: ApplicationConfig = new ApplicationConfig();
  public daysPriorToSurgeryConfig: ApplicationConfig = new ApplicationConfig();
  public preOpMsgIdConfig: ApplicationConfig = new ApplicationConfig();
  public preOpMessageList: Message[] = [];

  constructor(
    private sessionHelper:SessionHelper,
    private loggingService: LoggingService,
    private loadingBarService: LoadingBarService,
    private notificationBarService: NotificationBarService,
    private notifyGeneralService: NotifyGeneralService,
    private sessionService: SessionService,
    private encryptionService: EncryptionService,
    private notifyMessagesService: NotifyMessagesService
    ) { 
    }

  ngOnInit(): void {
    if(this.sessionHelper.isValidUserSession()){
      this.loadingBarService.startBar();
      this.customerId = this.sessionHelper.getCustomerId();
      // Load Page Data
      this.loadPageData();
    }
  }

  private loadPageData(){
    let _customerNotifyConfigsViewModel:ApplicationConfigViewModel[] = [];
    let _customerNotifyConfig:ApplicationConfigViewModel = new ApplicationConfigViewModel();
    this.getLanguagePrefernces();
    this.getNotifyMessages();
    this.notifyGeneralService.getCustomerNotifyConfig(this.customerId)
      .subscribe({
          next: (result: CloudApiResponse) => {
                this.loggingService.logVerbose(result);
                if(result != null && result.payload != null) {
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(result.payload);
                  this.customerNotifyConfigs = JSON.parse(decryptedPayload);
                  this.loggingService.logVerbose(this.customerNotifyConfigs);                  
                  this.customerNotifyConfigs.forEach(config => {
                    // Create the application Config View Model
                    if (!config.Name.includes('Auto')) {
                      _customerNotifyConfig = new ApplicationConfigViewModel();
                      _customerNotifyConfig.Name = config.Name;
                      _customerNotifyConfig.DisplayName = config.DisplayName;
                      _customerNotifyConfig.Value = config.Value;
                      _customerNotifyConfig.IsEncrypted = config.IsEncrypted;
                      _customerNotifyConfigsViewModel.push(_customerNotifyConfig);
                      if(config.Name == "IsSMSOptInEnabled"){
                        this.isSMSOptInEnabled = (config.Value == "1") ? true: false;
                      }
                    } else {
                      if (config.Name == 'SendAutoWelcomeTextEnabled')
                        this.isWelcomeTextEnabledConfig = config; 
                      else if (config.Name == 'SendAutoPreOpMessageEnabled')
                        this.isPreOpMessageEnabledConfig = config; 
                      else if (config.Name == 'SendAutoWelcomeText_HrsPriorToSurgery')
                        this.hrsPriorToSurgeryConfig = config;
                      else if (config.Name == 'SendAutoPreOpMessage_DaysPriorToSurgery')
                        this.daysPriorToSurgeryConfig = config;
                      else if (config.Name == 'SendAutoPreOpMessage_MessageId')
                        this.preOpMsgIdConfig = config;
                    }
                  });
                  this.customerNotifyConfigsViewModel = _customerNotifyConfigsViewModel;
                }
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.loggingService.logError("Error fetching notify preferences");
                this.notificationBarService.showError("Error fetching data");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed fetching notify preferences"); }
      });
  }

  private getLanguagePrefernces(){
    this.notifyGeneralService.getLanguages(this.customerId, false)
          .subscribe({
              next: (langPreferencesResult: CloudApiResponse) => {
                    this.loggingService.logVerbose(langPreferencesResult);
                    var langPreferences: CustomerLanguagePreference[] = langPreferencesResult.payload;
                    this.languagePreferences = langPreferences;
                  },
              error: () => { 
                    this.loggingService.logError("Error fetching languages");
                    this.notificationBarService.showError("Error fetching data"); 
                  },
              complete: () => { this.loggingService.logVerbose("Completed fetching languages"); }            
            });
  }

  private getNotifyMessages() {    
    this.notifyMessagesService.getMessage(this.customerId)
      .subscribe({
          next: (messageResult: CloudApiResponse) => {
                var decryptedPayload = this.encryptionService.decryptUsingAES256(messageResult.payload);
                this.loggingService.logVerbose(decryptedPayload);
                var message: Message[] = JSON.parse(decryptedPayload);
                this.preOpMessageList = message.filter(m => m.MessageCategoryId.toLowerCase() != '336CCC86-0ADF-4981-85AD-EBBF33976CD5'.toLowerCase() 
                                                        && m.LanguageName.toLowerCase() == 'english');                                 
              },
          error: () => { this.loggingService.logError("Error loading messages"); },
          complete: () => { this.loggingService.logVerbose("Completed loading messages"); }
      });
                
  }

  public onChangeLanguagePreference(event:MatSlideToggleChange, languageId:string){
    this.loadingBarService.startBar(); 
    this.loggingService.logVerbose(languageId);
    this.loggingService.logVerbose(event.checked);
    this.setLanguagePreference(languageId, event.checked);
  }

  private setLanguagePreference(languageId:string, enabledForNotify:boolean){
    let langPref:CustomerLanguagePreference = new CustomerLanguagePreference();
    langPref.languageId = languageId;
    langPref.isEnabledForNotify = enabledForNotify;
    this.loggingService.logVerbose(langPref);
    this.notifyGeneralService.setLanguagePreference(this.customerId, langPref)
      .subscribe({
          next: (isSetResult:CloudApiResponse) => {
                var isSet:boolean = isSetResult.payload;
                if(isSet){
                  this.notificationBarService.showSuccess("Preference updated");
                } else {
                  this.notificationBarService.showSuccess("Preference update failed");
                }
                // Delete the language session storage object used by notify page
                this.sessionService.removeStorageItem(sessionStorageItem.notifyLanguages)
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.loggingService.logError("Error setting language preference");
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed setting language preference"); }
      });
  }

  public onChangeCustomerOptInPreference(event:MatSlideToggleChange){
    this.loadingBarService.startBar(); 
    this.setCustomerNotifyConfig(this.getCustomerNotifyOptInConfig());
  }

  private setCustomerNotifyConfig(customerNotifyConfig:ApplicationConfig){
    // associate proper customer Id
    this.notifyGeneralService.setCustomerNotifyConfig(this.customerId, customerNotifyConfig)
      .subscribe({
          next: (isSetResult:CloudApiResponse) => {
                var isSet:boolean = isSetResult.payload;
                if(isSet){
                  this.notificationBarService.showSuccess("Preference updated");
                  // Clear browser session
                  if (customerNotifyConfig.Name.toLowerCase() == "issmsoptinenabled"){
                    this.sessionHelper.clearIsSMSOptIn();
                  }
                } else {
                  this.notificationBarService.showSuccess("Preference update failed");
                }
                this.loadingBarService.stopBar(); 
              },
          error: () => {
                this.loggingService.logError("Error setting notify preference");
                this.notificationBarService.showError("Error calling service");
                this.loadingBarService.stopBar(); 
              },
          complete: () => { this.loggingService.logVerbose("Completed setting notify preference"); }
      });
  }

  private getCustomerNotifyOptInConfig():ApplicationConfig{
    let applicationConfig = new ApplicationConfig();
    applicationConfig = this.customerNotifyConfigs.find(m => m.Name == "IsSMSOptInEnabled");
    if(applicationConfig != null){
      applicationConfig.Value = this.isSMSOptInEnabled ? "1" : "0";
    }
    return applicationConfig;
  }

  public startEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.IsEditing = true;
  }

  public cancelEdit(appConfig: ApplicationConfigViewModel): void {
    appConfig.Value = this.customerNotifyConfigsViewModel.find(m => m.Name == appConfig.Name).Value;
    appConfig.IsEditing = false;
  }

  public editConfig(appConfig: ApplicationConfigViewModel){
    this.loggingService.logVerbose(appConfig);
    this.setCustomerNotifyConfig(appConfig);
    appConfig.IsEditing = false;
  }

  public onWelcomeTextEnabled(enabled: boolean) {
    this.isWelcomeTextEnabledConfig.Value = enabled ? "true" : "false";
    this.setCustomerNotifyConfig(this.isWelcomeTextEnabledConfig);
  }

  public onPreOpMessageEnabled(enabled: boolean) {
    this.isPreOpMessageEnabledConfig.Value = enabled ? "true" : "false";
    this.setCustomerNotifyConfig(this.isPreOpMessageEnabledConfig);
  }

  public hrsPriorToSurgeryChanged() {
    this.setCustomerNotifyConfig(this.hrsPriorToSurgeryConfig);
  }

  public daysPriorToSurgeryChanged() {
    this.setCustomerNotifyConfig(this.daysPriorToSurgeryConfig);
  }

  public preOpMessageSelectionChanged() {
    this.setCustomerNotifyConfig(this.preOpMsgIdConfig);
  }
}
