// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup,FormControl,Validators } from '@angular/forms';

// Material
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatSelectChange as MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange as MatCheckboxChange } from '@angular/material/checkbox';

// Shared Lib
import { MessageBoxComponent, MessageBoxModel, MessageBoxType, CloudApiResponse } from 'kscigcorelib';
import { LoggingService, NotificationBarService, LoadingBarService, EncryptionService } from 'kscigcorelib';

// Application Core
import { RouteHelper } from '../../helpers/route.helper';

// Component
import { ContactEditorData, MobileCountryCode, MobileServiceProvider, PatientContact, MessageSendRequest, MessageSendResponse} from '../../../notify/notify-model';
import { NotifyContactsService } from '../../../settings/notify-contacts/notify-contacts.service';
import { NotifyService } from '../../../notify/notify.service';
import { ContactPointValidator } from './contact-point-validator';
import { SessionHelper } from '../../helpers/session.helper';
import { SharedPatientService } from '../../services/sharedpatient.service'
import { ContactType, EmailSendType, OptInStatusType, SMSSendType } from 'src/app/notify/notify.enum';


@Component({
  selector: 'app-contact-editor',
  templateUrl: './contact-editor.component.html',
  styleUrls: ['./contact-editor.component.css']
})
export class ContactEditorComponent implements OnInit {

  public contactEditorForm: UntypedFormGroup;
  public isPatientContact:boolean;
  public isContactPreferred:boolean;
  public isSMSPreferred:boolean;
  public isEmailPreferred:boolean;
  public contactEditorData:ContactEditorData;
  public selectedMobileCountryCode: MobileCountryCode;
  public mobileServiceProvidersToShow: MobileServiceProvider[] = [];
  public currentPatientGuid: string;
  public isAdd: boolean;
  public isDisableSave: boolean = false;
  public isSendWelcomeText: boolean;
  public isSendWelcomeTextFailed: boolean;
  public sendWelcomeTextMessageFailed:string;
  public sendWelcomeTextMessageStatus:string = "Sending Welcome Text...";
  public isOptInSMSEnabled = false;

  constructor(
    private loggingService:LoggingService,
    private loadingBarService:LoadingBarService,
    private notificationBarService: NotificationBarService,
    private notifyContactsService:NotifyContactsService,
    private encryptionService:EncryptionService,
    private notifyService:NotifyService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ContactEditorComponent>, 
    private patientService: SharedPatientService,
    private sessionHelper:SessionHelper,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inputData: ContactEditorData
    ) { 
      this.isPatientContact = false;
      this.isContactPreferred = false;
      this.isSMSPreferred = false;
      this.isEmailPreferred = false;
      this.isSendWelcomeText = false;
      this.contactEditorForm = this.fb.group({
        firstName: [null, [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        middleName: [null, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        lastName: [null, [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        alias: [null, [Validators.maxLength(50), Validators.pattern("^[a-zA-Z ,.'-]*$")]],
        language: [null, [Validators.required]],
        email: [null, [Validators.email]],
        countryCode: [null, [Validators.required]],
        mobile: [null, [Validators.maxLength(10), Validators.pattern("^[0-9]{10}")]],
        mobileProvider: [null, [Validators.required]],
      }, { 
        validators: ContactPointValidator()
      });
    }

  ngOnInit() {
   this.loadPageData();
  }

  private loadPageData(){
    this.loggingService.logVerbose(this.inputData);
    this.contactEditorData = this.inputData;
    if(this.inputData != null && this.inputData.contactData != null){
      this.loggingService.logVerbose("Edit Contact");
      this.isAdd = false;
    } else {
      this.loggingService.logVerbose("New Contact");
      this.isAdd = true;
    }
    this.setPageData();
  }

  private setPageData(){
    // Contact Type data
    if(this.contactEditorData.contactCategoryData != null) {
      this.isPatientContact = !this.contactEditorData.contactCategoryData.IsDeletable;
    }

    if(this.isPatientContact){
      this.isContactPreferred = true;
      this.isOptInSMSEnabled = this.contactEditorData.isOptInSMSEnabled;
    } else {
      this.isOptInSMSEnabled = false;
    }
    
    // Contact data
    if(this.contactEditorData.contactData != null){
      this.contactEditorForm.controls['firstName'].setValue(this.contactEditorData.contactData.FirstName);
      this.contactEditorForm.controls['middleName'].setValue(this.contactEditorData.contactData.MiddleName);
      this.contactEditorForm.controls['lastName'].setValue(this.contactEditorData.contactData.LastName);
      this.contactEditorForm.controls['alias'].setValue(this.contactEditorData.contactData.Alias);
      this.contactEditorForm.controls['mobile'].setValue(this.contactEditorData.contactData.Mobile);
      this.contactEditorForm.controls['email'].setValue(this.contactEditorData.contactData.Email);
      this.contactEditorForm.controls['language'].setValue(this.contactEditorData.contactData.LanguageId);
      this.contactEditorForm.controls['countryCode'].setValue(this.contactEditorData.contactData.MobileCountryCodeId);
      // Set the MobileServiceProviderList based on the MobileCountryCodeId
      this.setMobileServiceProviderList(this.contactEditorData.contactData.MobileCountryCodeId);
      this.contactEditorForm.controls['mobileProvider'].setValue(this.contactEditorData.contactData.MobileServiceProviderId);
      this.isContactPreferred = this.contactEditorData.contactData.IsContactPreferred;
      this.isEmailPreferred = this.contactEditorData.contactData.IsEmailPreferred;
      this.isSMSPreferred = this.contactEditorData.contactData.IsSMSPreferred;
    } else {
      // Select  country code & service provider
      if(this.contactEditorData.mobileCountryCodeData != null && this.contactEditorData.mobileCountryCodeData.length > 0){
        this.contactEditorForm.controls['countryCode'].setValue(this.contactEditorData.mobileCountryCodeData[0].mobileCountryCodeId);
        this.setMobileServiceProviderList(this.contactEditorData.mobileCountryCodeData[0].mobileCountryCodeId);
      }
      // Set language
      if(this.contactEditorData.languageData != null && this.contactEditorData.languageData.length > 0){
        this.contactEditorForm.controls['language'].setValue(this.contactEditorData.languageData.find(x=>x.isDefault == true).languageId);
      }
    }

    // get selected/current patient
    var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
    if (currPatient != null) {
      this.currentPatientGuid = currPatient.PatientGuid;  
    }
  }

  public onChangeMobileCountryCode(event:MatSelectChange){
    this.loggingService.logVerbose(event.value);
    this.setMobileServiceProviderList(event.value);
  }

  private setMobileServiceProviderList(mobileCountryCodeId:string){
    let mobileServiceProviders: MobileServiceProvider[] = [];
    if(this.contactEditorData.mobileServiceProviderData != null && this.contactEditorData.mobileServiceProviderData.length > 0){
      this.contactEditorData.mobileServiceProviderData.forEach((mobileServiceProvider)=>{
        if(mobileServiceProvider.mobileCountryCodeId == mobileCountryCodeId){
          mobileServiceProviders.push(mobileServiceProvider);
        }
      });
    }
    this.mobileServiceProvidersToShow = mobileServiceProviders;
    if(this.mobileServiceProvidersToShow.length > 0){
      this.contactEditorForm.controls['mobileProvider'].setValue(this.mobileServiceProvidersToShow[0].mobileServiceProviderId);
    }
  }

  public closeDialog(refresh:boolean) {
    this.dialogRef.close(refresh);
  }

  get firstName() {
    return this.contactEditorForm.get('firstName');
  }
  get middleName() {
    return this.contactEditorForm.get('middleName');
  }
  get lastName() {
    return this.contactEditorForm.get('lastName');
  }

  get alias() {
    return this.contactEditorForm.get('alias');
  }

  get language() {
    return this.contactEditorForm.get('language');
  }

  get email() {
    return this.contactEditorForm.get('email');
  }

  get countryCode() {
    return this.contactEditorForm.get('countryCode');
  }

  get mobile() {
    return this.contactEditorForm.get('mobile');
  }

  get mobileProvider() {
    return this.contactEditorForm.get('mobileProvider');
  }

  public onChangeIsContactPreferred(event:MatCheckboxChange){
    this.isContactPreferred = event.checked;
    this.contactEditorForm.markAsDirty();
  }

  public onChangeIsEmailPreferred(event:MatCheckboxChange){
    this.isEmailPreferred = event.checked;
    this.contactEditorForm.markAsDirty();
  }

  public onChangeIsSMSPreferred(event:MatCheckboxChange){
    this.isSMSPreferred = event.checked;
    this.contactEditorForm.markAsDirty();
  }

  public saveContact(){
    this.isSendWelcomeText = false;
    this.isSendWelcomeTextFailed = false;
    this.sendWelcomeTextMessageFailed = "";
    if (this.contactEditorForm.valid) {
      this.loadingBarService.startBar();
      let contact = this.getContactFormData();

      // Update Language Name
      var lang = this.contactEditorData.languageData.find(x=>x.languageId == contact.LanguageId);
      contact.LanguageName = '';
      if(lang != undefined && lang != null){
        contact.LanguageName = lang.languageName;
      }

      // Add New
      if(this.inputData.contactData == null){
        if(this.isPatientContact){
          if(this.currentPatientGuid != null){
            // On Premise Patient Contact
            this.loggingService.logVerbose("On Premise Patient Contact");
            let patContact:PatientContact = new PatientContact();
            patContact.PatientGUID = this.currentPatientGuid,
            patContact.FirstName = contact.FirstName,
            patContact.MiddleName = contact.MiddleName,
            patContact.LastName = contact.LastName,
            patContact.Alias = contact.Alias,
            patContact.Email = contact.Email,
            patContact.Mobile =  contact.Mobile;
            patContact.MobileCountryCodeId =  contact.MobileCountryCodeId;
            patContact.MobileServiceProviderId =  contact.MobileServiceProviderId;
            patContact.LanguageId = contact.LanguageId,
            patContact.LanguageName = contact.LanguageName,
            patContact.IsContactPreferred = contact.IsContactPreferred,
            patContact.IsEmailPreferred = contact.IsEmailPreferred,
            patContact.IsSMSPreferred = contact.IsSMSPreferred,
            patContact.ContactCategoryId = contact.ContactCategoryId,
            patContact.ContactType = contact.ContactType
            this.notifyService.addPatientContact(this.sessionHelper.getCustomerId(), patContact)
              .subscribe({
                next: (result:CloudApiResponse) => {  
                      if(result != null && result.payload != null){
                        this.notificationBarService.showSuccess("Added " +  contact.FirstName + " " + contact.LastName);
                        this.loggingService.logInformation("Patient Contact " +  contact.FirstName + " " + contact.LastName + " has been added " + result);
                        if((this.isOptInSMSEnabled) && (contact.Mobile != null && contact.Mobile.length > 0)){
                          contact.ContactId = result.payload; // The payload has the contact id of the newly created contact
                          this.isSendWelcomeText = true; // Send Welcome text to the newly added contact if Mobile number present
                          this.notifyService.sendMessage(this.contactEditorData.customerId, this.getMessageSendRequest(contact))
                            .subscribe({
                              next: (messageSendResponseResult:CloudApiResponse) => {
                                this.loggingService.logVerbose(messageSendResponseResult);
                                var decryptedContactPayload = this.encryptionService.decryptUsingAES256(messageSendResponseResult.payload);
                                var messageSendResponse: MessageSendResponse = JSON.parse(decryptedContactPayload);
                                this.loggingService.logVerbose(messageSendResponse);
                                if(messageSendResponse != null){
                                  if((messageSendResponse.IsSent)){
                                    this.loggingService.logVerbose("Welcome text sent");
                                    this.notificationBarService.showSuccess("Welcome text sent");
                                    this.closeDialog(true);
                                  } else {
                                    this.isDisableSave = true;
                                    this.notificationBarService.showError("Failed sending welcome text");
                                    this.loggingService.logError("Failed sending welcome text");
                                    this.isSendWelcomeTextFailed = true;
                                    this.sendWelcomeTextMessageFailed = messageSendResponse.SMSSendResponse.SMSProviderResponses[0].ResponseMessage;
                                    this.sendWelcomeTextMessageStatus = "Contact Saved.";
                                  }
                                }
                                this.loadingBarService.stopBar();
                              },
                              error: () => {
                                this.isSendWelcomeText = false;
                                this.isSendWelcomeTextFailed = true;
                                this.notificationBarService.showError("Error sending welcome text to " + contact.MobileCountryCode + contact.Mobile);
                                this.sendWelcomeTextMessageFailed = "Error sending welcome text to " + contact.MobileCountryCode + contact.Mobile;
                                this.loadingBarService.stopBar();
                              },
                              complete: () => { this.loggingService.logVerbose("Completed sending Optin text to " + contact.FirstName + " " + contact.LastName); }
                            });
                        } else {
                          this.loggingService.logVerbose("No mobile number to send Welcome text to or SMSOptIn is disabled");
                          this.loadingBarService.stopBar();
                          this.closeDialog(true);
                        }
                      } else {
                        this.notificationBarService.showError("Error adding " +  contact.FirstName + " " + contact.LastName);
                        this.loggingService.logError("Patient Contact " +  contact.FirstName + + " " + contact.LastName + " has been added " + result);
                      }
                    },
                error: () => {
                      this.notificationBarService.showError("Error adding " + contact.FirstName + " " + contact.LastName);
                      this.loggingService.logError("Error adding Patient contact " + contact.FirstName + " " + contact.LastName);
                      this.loadingBarService.stopBar();
                    },
                complete: () => { this.loggingService.logVerbose("Completed adding Patient contact " + contact.FirstName + " " + contact.LastName); }
            });
          } 
        } else {
          // Global Contact
          this.notifyContactsService.addGlobalContact(this.contactEditorData.customerId, contact)
            .subscribe({
                next: (result) => {
                      this.notificationBarService.showSuccess("Added " +  contact.FirstName + " " + contact.LastName);
                      this.loggingService.logInformation("Global Contact " + contact.FirstName + " " + contact.LastName + " has been added");
                      
                      contact.ContactId = result.payload;
                      if(contact.ContactId != null){
                        this.notificationBarService.showSuccess("Added: " + contact.FirstName + " " + contact.LastName);
                      }
                      this.loadingBarService.stopBar();
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error adding " +  contact.FirstName + " " + contact.LastName);
                      this.loggingService.logError("Error adding Contact " +  contact.FirstName + " " + contact.LastName);
                      this.loadingBarService.stopBar();
                    },
                complete: () => { this.loggingService.logVerbose("Completed adding Contact " +  contact.FirstName + " " + contact.LastName); }
            });
        }
      } else {
        // Update 
        if(this.isPatientContact){
          if(this.inputData.contactCategoryData.ContactCategoryName.toLowerCase() == "patient contact"){
            // On Premise Patient Contact            
            this.notifyService.updatePatientContact(this.sessionHelper.getCustomerId(), contact)
              .subscribe({
                  next: (result) => {  
                        this.notificationBarService.showSuccess("Updated " +  contact.FirstName + " " + contact.LastName);
                        this.loggingService.logInformation("Contact " +  contact.FirstName + " " + contact.LastName + " has been updated");  
                        if (this.isOptInSMSEnabled 
                            && (contact.Mobile != null && contact.Mobile.length > 0)
                            && (this.inputData.contactData.Mobile != contact.Mobile || this.inputData.contactData.MobileCountryCodeId != contact.MobileCountryCodeId)){
                          this.isSendWelcomeText = true; // Only send Welcome text if Mobile number was updated
                          // Reset the ContactId in case a new Contact was created and old one was soft deleted
                          if(result.payload != null){
                            contact.ContactId = result.payload;
                          }
                          this.notifyService.sendMessage(this.contactEditorData.customerId, this.getMessageSendRequest(contact))
                            .subscribe({
                              next: (messageSendResponseResult:CloudApiResponse) => {
                                this.loggingService.logVerbose(messageSendResponseResult);
                                var decryptedContactPayload = this.encryptionService.decryptUsingAES256(messageSendResponseResult.payload);
                                var messageSendResponse: MessageSendResponse = JSON.parse(decryptedContactPayload);
                                this.loggingService.logVerbose(messageSendResponse);
                                if(messageSendResponse != null){
                                  if((messageSendResponse.IsSent)){
                                    this.loggingService.logVerbose("Welcome text sent");
                                    this.notificationBarService.showSuccess("Welcome text sent");
                                    this.closeDialog(true);
                                  } else {
                                    this.isDisableSave = true;
                                    this.notificationBarService.showError("Failed sending welcome text");
                                    this.loggingService.logError("Failed sending welcome text");
                                    this.isSendWelcomeTextFailed = true;
                                    this.sendWelcomeTextMessageFailed = messageSendResponse.SMSSendResponse.SMSProviderResponses[0].ResponseMessage;
                                    this.sendWelcomeTextMessageStatus = "Contact Saved.";
                                  }
                                }
                                this.loadingBarService.stopBar();
                              },
                              error: () => {
                                this.isSendWelcomeText = false;
                                this.isSendWelcomeTextFailed = true;
                                this.notificationBarService.showError("Error sending welcome text to " + contact.MobileCountryCode + contact.Mobile);
                                this.sendWelcomeTextMessageFailed = "Error sending welcome test to " + contact.MobileCountryCode + contact.Mobile;
                                this.loadingBarService.stopBar();
                              },
                              complete: () => { this.loggingService.logVerbose("Completed sending welcome text to " + contact.FirstName + " " + contact.LastName); }
                          });
                        } else {
                          this.loggingService.logVerbose("No mobile number to send Welcome text to or SMSOptIn is disabled");
                          this.loadingBarService.stopBar();                   
                          this.closeDialog(true);
                        }
                      },
                  error: () => {
                        this.notificationBarService.showError("Error updating " +  contact.FirstName + " " + contact.LastName);
                        this.loggingService.logError("Error updating Patient contact " + contact.FirstName + " " + contact.LastName);
                        this.loadingBarService.stopBar();
                      },
                  complete: () => { this.loggingService.logVerbose("Completed updating Patient contact " + contact.FirstName + " " + contact.LastName); }
              });
          } 
        } else {
          this.notifyContactsService.updateGlobalContact(this.contactEditorData.customerId, contact)
            .subscribe({ 
                next: () => {
                      this.notificationBarService.showSuccess("Updated " +  contact.FirstName + " " + contact.LastName);
                      this.loggingService.logInformation("Contact " +  contact.FirstName + " " + contact.LastName + " has been updated");
                      this.loadingBarService.stopBar();
                      this.closeDialog(true);
                    },
                error: () => {
                      this.notificationBarService.showError("Error Updating " +  contact.FirstName + " " + contact.LastName);
                      this.loggingService.logError("Error updating global Contact " +  contact.FirstName + " " + contact.LastName);
                      this.loadingBarService.stopBar();
                    },
                complete: () => { this.loggingService.logVerbose("Completed updating global Contact " +  contact.FirstName + " " + contact.LastName); }
            });
        }
      }
    } else {
      this.loggingService.logVerbose("Form has errors.");
    } 
  }

  private getContactFormData():PatientContact{
    let contact:PatientContact = new PatientContact();
    if(this.inputData.contactData != null){
      contact.ContactId = this.inputData.contactData.ContactId;
      contact.OptInStatusEnumVal = this.inputData.contactOptInStatus;
      contact.ContactType = this.inputData.contactData.ContactType;
    }
    else {
      if(this.isPatientContact){
        contact.ContactType = ContactType.Patient;
      } else {
        contact.ContactType = ContactType.Global;
      }
    }
    contact.ContactCategoryId = this.inputData.contactCategoryData.ContactCategoryId;
    contact.FirstName =  this.firstName.value;
    contact.MiddleName =  this.middleName.value;
    contact.LastName =  this.lastName.value;
    contact.Alias =  this.alias.value;
    contact.LanguageId = this.language.value;
    contact.Email =  this.email.value;
    contact.Mobile =  this.mobile.value;
    contact.MobileCountryCodeId =  this.countryCode.value;
    contact.MobileServiceProviderId =  this.mobileProvider.value;
    contact.IsContactPreferred =  this.isContactPreferred;
    // Fixing the bug where ispreferred SMS and Email get checked even if email & mobile value are empty
    if(this.email.value == null || this.email.value == ''){
      contact.IsEmailPreferred =  false;
    } else {
      contact.IsEmailPreferred =  this.isEmailPreferred;
    }

    if(this.mobile.value == null || this.mobile.value == ''){
      contact.IsSMSPreferred =  false;
    } else {
      contact.IsSMSPreferred =  this.isSMSPreferred;
    }
    return contact;
  }
  
  private getMessageSendRequest(contact:PatientContact):MessageSendRequest{
    let messageSendRequest:MessageSendRequest = new MessageSendRequest();
    var recipientList:PatientContact[] = [];
    if(contact != null)
      recipientList.push(contact);
    messageSendRequest.emailType = EmailSendType.None;
    messageSendRequest.recipientList = recipientList;
    messageSendRequest.smsType = SMSSendType.NotifyModule_OptInText;
    messageSendRequest.message = this.inputData.systemMessage;
    return messageSendRequest;
  }
}
