<div class="contentBox">
        
    <div id="activityContainer" >
        
        <div class="activityPanel" >
            
            <div class="filterPanel">
                &nbsp;&nbsp;
                <mat-form-field *ngIf="moduleTypes" id="moduleType">
                    <mat-label>Module Type</mat-label>
                    <mat-select (selectionChange)="this.hideActivityDetails();refreshActivityLogs()" [(value)]="selectedModType" >
                        <mat-option *ngFor="let modType of moduleTypes" [value]="modType">
                            {{modType}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>  
                &nbsp;&nbsp;    
                <mat-form-field *ngIf="timeFilters" id="timeFilter">
                    <mat-label>Time</mat-label>
                    <mat-select (selectionChange)="timeFilterChanged($event)" [(value)]="selectedTimeFilterId" >
                        <mat-option *ngFor="let timeFilter of timeFilters" [value]="timeFilter.filterId">
                            {{timeFilter.filterName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" (selectedIndexChange)="hideActivityDetails()">        
                <mat-tab label="User"> 
                    <div class="overflowAuto" style="height: calc(100vh - 214px);">
                        <mat-list>
                            <ng-container *ngFor="let sessionLog of sessionLogs">
                                <mat-list-item>
                                    {{sessionLog.ActivityDate | date:'MM/dd/yyyy hh:mm:ss'}}                                
                                    -&nbsp;{{sessionLog.Description}}
                                    &nbsp;<a class="detailsLink" *ngIf="sessionLog.DetailDescription" (click)="showActivityDetails(sessionLog.DetailDescription, $event.target)"><u>Details</u></a>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>    
                    </div>
                </mat-tab>
                <mat-tab label="Patient" *ngIf="patientGuid">
                    <div class="overflowAuto" style="height: calc(100vh - 214px);">
                        <mat-list>
                            <ng-container *ngFor="let patLog of patientLogs">
                                <mat-list-item>
                                    {{patLog.ActivityDate}}                                
                                    -&nbsp;{{patLog.Description}}
                                    &nbsp;<a class="detailsLink" *ngIf="patLog.DetailDescription" (click)="showActivityDetails(patLog.DetailDescription, $event.target)"><u>Details</u></a>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </div>
                </mat-tab>
            </mat-tab-group>
            
        </div>
        <!-- Activity Detail -->
        <div id="detailsPanel" class="displayHidden" >
            <div class="contentBox">
                <div class="CaptionLabel">Activity Details <mat-icon title="close" (click)="hideActivityDetails()">close</mat-icon></div>                
            </div>
            <div id="detailContent" [innerHTML]="detailHtml" class="overflowAuto contentBoxSquare backgroundWhite borderBlue Padding5px" style="height: calc(100vh - 270px);">            
            </div>
        </div>
    </div>
</div>
