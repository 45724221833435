// Angular
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

// Shared Lib
import { CloudApiResponse, ActivityLogViewModel, ActivityTimeFilter } from 'kscigcorelib';
import { NotificationBarService, LoadingBarService, LoggingService, EncryptionService, MessageBoxModel, MessageBoxType, MessageBoxComponent } from 'kscigcorelib';

// Application
import {ActivityService} from './activity.service'
import { SessionHelper } from '../shared/helpers/session.helper';
import { SharedPatientService } from '../shared/services/sharedpatient.service';
import { EnumActivityModuleType } from './activity.enum';


@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  public customerId:string;
  public sessionId:string;
  public patientGuid:string;
  
  public patientLogs:ActivityLogViewModel[];
  public sessionLogs:ActivityLogViewModel[];
  public timeFilters:ActivityTimeFilter[];
  public moduleTypes: string[] = Object.values(EnumActivityModuleType);
  public selectedModType: string = "All";
  public selectedTimeFilterId:number = 0;

  public detailHtml:string;

  constructor(
    private activityService:ActivityService,
    private loggingService:LoggingService,
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private encryptionService:EncryptionService,
    private notificationBarService: NotificationBarService,
    private patientService: SharedPatientService
    ) { }

  ngOnInit() {
    this.loadingBarService.startBar();
    this.customerId = this.sessionHelper.getCustomerId();
    this.sessionId = this.sessionHelper.getSessionId();
    var currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()));
    if (currPatient != null) {
      this.patientGuid = currPatient.PatientGuid;      
    }

    this.updateModuleTypesFilter();

    // Get Page Data
    this.getPageData();
  }

  getPageData(){

    this.activityService.getTimeFilters()
            .subscribe({
              next: (result: CloudApiResponse) => { 
                    this.loggingService.logVerbose(result.payload);
                    this.timeFilters = result.payload;    
                  
                    // Get Session & Patient Logs
                    this.selectedTimeFilterId = 1;
                    this.refreshActivityLogs();
                    },
              error: () => { this.loggingService.logError("Failed to get Activity log time filters."); },
              complete: () => { this.loggingService.logVerbose("Completed getting Activity log time filters."); }
            });
  }

  updateModuleTypesFilter() {    
    if (!this.sessionHelper.isMediaModuleEnabled()) {
      this.moduleTypes = this.moduleTypes.filter(m => m != "Media");
    }
    if (!this.sessionHelper.isNotifyModuleEnabled()) {
      this.moduleTypes = this.moduleTypes.filter(m => m != "Notify");
    }
    if (!this.sessionHelper.isWorklistModuleEnabled()) {
      this.moduleTypes = this.moduleTypes.filter(m => m != "Worklist");
    }
  }

  timeFilterChanged(event) {  
    this.hideActivityDetails();
    this.refreshActivityLogs();
  }

  refreshActivityLogs() {
    let timeFilterName:string = this.timeFilters.find(m => m.filterId == this.selectedTimeFilterId).filterName;
    // Get Session Logs
    this.activityService.getActivityLogForSession(this.customerId, this.selectedTimeFilterId, this.selectedModType)
          .subscribe({
            next: (sessLogResult: CloudApiResponse) => {   
                  var decryptedPayload = this.encryptionService.decryptUsingAES256(sessLogResult.payload)
                  this.sessionLogs = JSON.parse(decryptedPayload);        
                  this.loggingService.logVerbose(this.sessionLogs);
                  this.activityService.getActivityLogForSession_Sensitive(this.customerId, timeFilterName, this.selectedModType)
                      .subscribe({
                        next: (sessLogResult: CloudApiResponse) => {                   
                                var decryptedPayload = this.encryptionService.decryptUsingAES256(sessLogResult.payload); 
                                this.loggingService.logVerbose(decryptedPayload);
                                let sensitiveSessionLogs:ActivityLogViewModel[] = JSON.parse(decryptedPayload);
                                this.sessionLogs.push(...sensitiveSessionLogs);
                                this.sessionLogs.sort((a, b) => a.ActivityDate < b.ActivityDate ? 1 : -1);
                                this.loadingBarService.stopBar();
                              },
                        error: () => { this.loggingService.logError("Failed to get Activity log (sensitive data) for session - TimeFilter: " + timeFilterName ); },
                        complete: () => { this.loggingService.logVerbose("Completed getting Activity log (sensitive data) for session - TimeFilter: " + timeFilterName); }
                      });
                  },
            error: () => { this.loggingService.logError("Failed to get Activity log (non-sensitive data) for session - TimeFilter: " + timeFilterName); }
          });

    // Get Patient Logs
    if (this.patientGuid != null) {
      this.activityService.getActivityLogForPatient(this.customerId, this.patientGuid, this.selectedTimeFilterId, this.selectedModType)
          .subscribe({
              next: (patLogResult: CloudApiResponse) => { 
                    this.patientLogs = JSON.parse(this.encryptionService.decryptUsingAES256(patLogResult.payload));        
                    this.loggingService.logVerbose(this.sessionLogs);
                    
                    this.activityService.getActivityLogForPatient_Sensitive(this.customerId, this.patientGuid, timeFilterName, this.selectedModType)
                        .subscribe({
                          next: (sessLogResult: CloudApiResponse) => {                   
                                  var decryptedPayload = this.encryptionService.decryptUsingAES256(sessLogResult.payload); 
                                  this.loggingService.logVerbose(decryptedPayload);
                                  let sensitivePatientLogs:ActivityLogViewModel[] = JSON.parse(decryptedPayload);
                                  this.patientLogs.push(...sensitivePatientLogs);

                                  this.patientLogs.sort((a, b) => a.ActivityDate < b.ActivityDate ? 1 : -1);

                                  this.loadingBarService.stopBar();
                                  },
                          error: () => { this.loggingService.logError("Failed to get Activity log (sensitive data) for Patient " + this.patientGuid + "; TimeFilter: " + timeFilterName )},
                          complete: () => { this.loggingService.logVerbose("Completed getting Activity log (sensitive data) for Patient " + this.patientGuid + "; TimeFilter: " + timeFilterName )}
                        });
                      },
              error: () => { this.loggingService.logError("Failed to get Activity log (non-sensitive data) for Patient"); }
          });
    }
  }

  public showActivityDetails(htmlStr:string, target: HTMLElement) {
    this.clearSelection();

    var parentElem: HTMLElement = target.parentElement;
    parentElem.parentElement.parentElement.parentElement.classList.add("isActive");
    this.detailHtml = htmlStr;
    this.displayActivityDetailsPanel(true);
  }

  private clearSelection() {
    var listItemElems = document.querySelectorAll<HTMLElement>(".mat-mdc-list-item")
    listItemElems.forEach((elem) => {
      elem.classList.remove("isActive");
    });
  }
  
  public hideActivityDetails() {
    this.clearSelection();
    this.displayActivityDetailsPanel(false);
  }

  displayActivityDetailsPanel(doShow:boolean) {
    if (doShow) {
      document.getElementById("detailsPanel").classList.remove("displayHidden");
      document.getElementById("detailsPanel").classList.add("activityDetailPanel");
      document.getElementById("detailsPanel").setAttribute("display", "inline-block");
    }
    else {
      document.getElementById("detailsPanel").classList.remove("activityDetailPanel");
      document.getElementById("detailsPanel").classList.add("displayHidden");
    }
  }
  
}
