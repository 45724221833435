// export enum ActionDefinition
// {
//     None = 0,
//     CaseStep = 1,
//     CaseTemplate = 2,
//     PatientData = 3,
//     MessageTemplate = 4,
//     ContactMessageLog = 5,
//     Location = 6,
//     Case = 7,
//     ActionDependency = 8,
//     NextOfKin = 9,
//     Media = 10,
//     GlobalMessagingContact = 11,
//     MessageCategory = 12
// }

export enum EnumUserAction
{
    LogOn = 1,
        LogOff = 2,

        MediaSelect = 100,
        MediaPlay = 101,
        MediaPause = 102,
        MediaStop = 103,
        MediaSeekStart = 104,
        MediaSeekStop = 105,
        MediaSelectionDelete = 106,


        ContactCategoryAdd = 200,
        ContactCategoryEdit = 201,
        ContactCategoryDelete = 202,
        ContactCategoryUndoDelete = 203,

        GlobalContactAdd = 204,
        GlobalContactEdit = 205,
        GlobalContactDelete = 206,
                
        AdHocContactAdd = 207,
        AdHocContactEdit = 208,
        AdHocContactDelete = 209,
                
        MessageCategoryAdd = 210,
        MessageCategoryEdit = 211,
        MessageCategoryDelete = 212,
        MessageCategoryUndoDelete = 213,

        MessageAdd = 214,
        MessageEdit = 215,
        MessageDelete = 216,
        MessageUndoDelete = 217,
        //MessageSend = 218,

        PatientSelect = 300,
        PatientDeselect = 301,

        PatientSearch = 302,

        PatientAdd = 303,
        PatientEdit = 304,
        PatientDelete = 305,
                
        PatientContactAdd = 306,
        PatientContactEdit = 307,
        PatientContactDelete = 308

}

export enum EnumActivityModuleType
{
    All = "All",
    Auth = "Auth",
    Media = "Media",
    Notify = "Notify",
    Worklist = "Worklist"
}


