import { Component, OnInit } from '@angular/core';

import { DeviceDetectorService } from '../shared/services/devicedetector.service';

// Shared Lib
import { CloudApiResponse, EnumMediaType, PatientModeAdminConfig } from 'kscigcorelib';
import { LoadingBarService, LoggingService, NotificationBarService, EncryptionService } from 'kscigcorelib';
import { EnumMediaModuleType, EnumPremierMediaSettings, EnumPatientModeTrigger } from 'kscigcorelib';
import { ApplicationConfig, MediaModel, MediaType, CustomerMediaData, CustomerDefaultMediaPreference , PatientModeSetting} from 'kscigcorelib';

// Application
import { RouteHelper } from '../shared/helpers/route.helper';
import { SessionHelper } from '../shared/helpers/session.helper';
import { MediaService } from '../shared/services/media.service'
import { appPageName } from '../shared/constants/app.enum';
import { SharedPatientService } from '../shared/services/sharedpatient.service'
import { EnumUserAction } from '../activity/activity.enum';

@Component({
  selector: 'app-media-play',
  templateUrl: './media-play.component.html',
  styleUrls: ['./media-play.component.css']
})
export class MediaPlayComponent implements OnInit {
  
  public isModuleEnabled:boolean = false;
  public isPremierMediaEnabled:boolean = false;
  
  
  public customerId:string;
  public sessionId:string;  
  public patientGuid: string;
  public savedMediaPref: CustomerMediaData = new CustomerMediaData();
  public defaultMediaPref: CustomerDefaultMediaPreference = new CustomerDefaultMediaPreference();
  public media: MediaModel = new MediaModel();
  public slideShowImages: MediaModel[] = [];
  public mediaLoadStatusMsg: string = 'Media loading...';

  public premierModuleTypeId: number = EnumMediaModuleType.Premier;
  public relaxationModuleTypeId: number = EnumMediaModuleType.Relaxation;

  public swankPortalId: string = '';
  public widevineLicenseServerUrl: string = '';
  public fairplayLicenseServerUrl: string = '';
  public fairplayCertificateUrl: string = '';

  public isPatientModeEnabled: boolean = false;
  public patientModePassword: string= '';

  public isPremierMediaSettingsLoaded = false;

  private deviceIsTablet: boolean;

  public emptyGuid = "00000000-0000-0000-0000-000000000000";
  
  constructor(
    private sessionHelper:SessionHelper,
    private loadingBarService: LoadingBarService,
    private loggingService:LoggingService,
    private mediaService:MediaService,
    private notificationBarService: NotificationBarService,
    private encryptionService: EncryptionService,
    private routeHelper: RouteHelper,
    private deviceService: DeviceDetectorService,
    private patientService: SharedPatientService
  ) { }

  ngOnInit() {
    this.isModuleEnabled = this.sessionHelper.isMediaModuleEnabled();
    this.isPremierMediaEnabled = this.sessionHelper.isPremierMediaEnabled();
    

    if(this.isModuleEnabled){      
      if(this.sessionHelper.isValidUserSession()){
        // Start loading bar
        this.loadingBarService.startBar();

        this.customerId = this.sessionHelper.getCustomerId();
        this.sessionId = this.sessionHelper.getSessionId();
       
        let currPatient = JSON.parse(this.encryptionService.decryptUsingAES256(this.patientService.getCurrentPatient()))
        if (currPatient != undefined) {
           this.patientGuid = currPatient.PatientGuid;
        }        

        this.deviceIsTablet = this.deviceService.isTablet();
        this.loggingService.logDebug("Device is tablet: " + this.deviceIsTablet);

        // Load Page Data
        this.loadPageData();       
      }
    }
  }

  
  private loadPageData() {
        
    this.loggingService.logDebug("Loading Patient mode");
    this.isPatientModeEnabled = this.sessionHelper.getPatientModeSetting().IsEnabled;
    this.patientModePassword = this.sessionHelper.getPatientModeAdminPassword(true);
    
    if (this.isPremierMediaEnabled)
      this.loadPremierMediaSettings();
          
    this.loadMediaData();

  }
    
  private loadPremierMediaSettings() {
    this.loggingService.logDebug("Loading Premier Media settings");
    this.mediaService.getMediaSettingsByCategory(this.customerId, EnumMediaModuleType[EnumMediaModuleType.Premier])
      .subscribe({
          next: (apiResponse: CloudApiResponse) => {
                var deserializedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                let mediaSettings: ApplicationConfig[]  = JSON.parse(deserializedResult);
                this.loggingService.logVerbose(mediaSettings);
                
                if (mediaSettings != null) {
                  this.loggingService.logDebug(mediaSettings);
                  
                  this.swankPortalId = this.extractValueFrom(mediaSettings.find(m => m.Name == EnumPremierMediaSettings.PremierMediaApiPortalId));
                  this.widevineLicenseServerUrl = this.extractValueFrom(mediaSettings.find(m => m.Name == EnumPremierMediaSettings.PremierWidevineLicenseServerUrl));
                  this.fairplayLicenseServerUrl = this.extractValueFrom(mediaSettings.find(m => m.Name == EnumPremierMediaSettings.PremierFairplayLicenseServerUrl));
                  this.fairplayCertificateUrl = this.extractValueFrom(mediaSettings.find(m => m.Name == EnumPremierMediaSettings.PremierFairplayCertificateUrl));
                
                  this.isPremierMediaSettingsLoaded = true;
                }
              },
          error: () => { this.loggingService.logError("Error getting Media Settings by Category " + EnumMediaModuleType.Premier); },
          complete: () => { this.loggingService.logVerbose("Completed getting Media Settings by Category " + EnumMediaModuleType.Premier); }
      });
  }

  private extractValueFrom(appConfig: ApplicationConfig): string {
    if (appConfig)
            return appConfig.Value && appConfig.IsEncrypted ? this.encryptionService.decryptUsingAES256(appConfig.Value) : appConfig.Value;
    return '';
  }

  // if Patient is selected load patient saved media data if exists, 
  // else if saved media exists for the current session, load saved session media
  // else load default media
  private loadMediaData() {
    if (this.patientGuid) {
      this.loggingService.logDebug("Loading Patient media data");
      this.mediaService.getPatientMediaData(this.customerId, this.patientGuid)
      .subscribe({
          next: (apiResponse: CloudApiResponse) => {
                var decryptedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                this.loggingService.logVerbose(decryptedResult);
                let patientMediaData: CustomerMediaData = JSON.parse(decryptedResult);
                                
                if (patientMediaData.MediaId != this.emptyGuid) {
                  this.loggingService.logDebug("Patient saved media data loaded");
                  this.loggingService.logDebug(patientMediaData);
                  this.updateSavedMediaPrefLocalVariable(patientMediaData);
                  this.loadMedia(patientMediaData.MediaId);            
                    
                } 
                else {
                  this.loggingService.logDebug("Patient saved media data does not exist");
                  this.loadSessionOrDefaultMediaData();
                }
              },
          error: () => { this.loggingService.logError("Error getting Patient Media data"); },
          complete: () => { this.loggingService.logVerbose("Completed getting Patient media data"); }
      });
    } 
    else {
      this.loadSessionOrDefaultMediaData();
    }
  }

  private loadSessionOrDefaultMediaData() {
    
    // get session media data if it exists, else get default media
    this.loggingService.logDebug("Loading Session saved media data");
    this.mediaService.getSessionMediaData(this.customerId, this.sessionId)
    .subscribe({
         next: (apiResponse: CloudApiResponse) => {
              var decryptedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
              this.loggingService.logVerbose(decryptedResult);
              let sessionMediaData: CustomerMediaData = JSON.parse(decryptedResult);
              
              if (sessionMediaData.MediaId != this.emptyGuid) {
                this.loggingService.logDebug("Session saved media data loaded: " + sessionMediaData.MediaId);
                this.loggingService.logVerbose(sessionMediaData);
                this.updateSavedMediaPrefLocalVariable(sessionMediaData);
                this.loadMedia(sessionMediaData.MediaId);
              } else {
                this.loggingService.logDebug("Session saved media data not found");
                this.loggingService.logDebug("Loading Default Media data");
                // get Customer Default Media Preference
                this.mediaService.getCustomerDefaultMediaPreference(this.customerId)
                    .subscribe({
                      next: (apiResponse: CloudApiResponse) => {
                              var decryptedResult = this.encryptionService.decryptUsingAES256(apiResponse.payload);
                              this.loggingService.logVerbose(decryptedResult);
                              this.defaultMediaPref = JSON.parse(decryptedResult);
                              
                              if (this.defaultMediaPref != null && this.defaultMediaPref.MediaId) {
                                this.loggingService.logDebug("Successfully loaded default media data: " + this.defaultMediaPref.MediaId);
                                this.loadMedia(this.defaultMediaPref.MediaId);     
                              } else {
                                this.loggingService.logDebug("Default media data not found");                                
                              }
                              this.loadingBarService.stopBar(); 
                            },
                      error: () => { this.loggingService.logError("Error getting Customer default media preference"); },
                      complete: () => { this.loggingService.logVerbose("Completed getting Customer default media preference"); }      
                    });        
              }
            },
         error: () => { this.loggingService.logError("Error getting Session media data for session " + this.sessionId); },
         complete: () => { this.loggingService.logVerbose("Completed getting Session media data for session " + this.sessionId); }
    });
  }

  private updateSavedMediaPrefLocalVariable(mediaPref: CustomerMediaData) {
    this.savedMediaPref.CustomerId = this.customerId;
    if (this.patientGuid) {
      this.savedMediaPref.PatientId = this.patientGuid;
    }
    this.savedMediaPref.SessionId = this.sessionId;
    this.savedMediaPref.MediaId = mediaPref.MediaId;    
    this.savedMediaPref.ShowSlideShow = mediaPref.ShowSlideShow;
    this.savedMediaPref.PlayStartPositionSeconds = mediaPref.PlayEndPositionSeconds; // to resume from last paused position
    this.savedMediaPref.PlayEndPositionSeconds = mediaPref.PlayEndPositionSeconds;    
  }

  
  private loadMedia(mediaId: string) {
    this.loggingService.logDebug("Loaging media " + mediaId);
    this.mediaService.getMediaAll(this.customerId, false)
        .subscribe({
            next: (mediaResult: CloudApiResponse) => {
                  var decryptedResult = this.encryptionService.decryptUsingAES256(mediaResult.payload);
                  this.loggingService.logVerbose(decryptedResult);
                  let mediaAll: MediaModel[] = JSON.parse(decryptedResult);
                  if (mediaAll) { 
                    this.media = mediaAll.find(m => m.MediaId == mediaId);            
                    this.slideShowImages = mediaAll.filter(m => m.MediaTypeId == EnumMediaType.Image.valueOf());  
                    
                    if (this.media != undefined) {
                      this.loggingService.logDebug("Successfully loaded media");
                      this.loggingService.logVerbose(this.media);
                      
                    } else {
                      this.mediaLoadStatusMsg = "Media not found";
                      this.loggingService.logDebug("Failed to load media " + mediaId + ". Media not found");
                    }
                  }
                  this.loadingBarService.stopBar();   
                },
            error: () => { this.loggingService.logError("Error getting Media all"); },
            complete: () => { this.loggingService.logVerbose("Completed getting Media all"); }
    });
  }

  setSessionMediaData(userAction: EnumUserAction) : Promise<void> {
    let successMsg: string;
    let errorMsg: string;
    let completedMsg: string;

    switch(userAction) {
      case EnumUserAction.MediaPlay:
        successMsg = "Logged Session Media Start";
        errorMsg = "Error logging Session Media Start"
        completedMsg = "Completed logging Session Media Start";
      break;
      case EnumUserAction.MediaStop:
        successMsg = "Successfully Saved session Media Data";
        errorMsg = "Error saving Session Media Data";
        completedMsg = "Completed saving Session Media Data";
      break;
      case EnumUserAction.MediaSeekStart:
        successMsg = "Logged Session Media Seek Start";
        errorMsg = "Error logging Session Media Seek Start";
        completedMsg = "Completed logging Session Media Seek Start";
      break;
      case EnumUserAction.MediaSeekStop:
        successMsg = "Logged Session Media Seek Stop";
        errorMsg = "Error logging Session Media Seek Stop";
        completedMsg = "Completed logging Session Media Seek Stop";
      break;
    }

    return new Promise((resolve, reject) => { 
      this.mediaService.setSessionMediaData(userAction.toString(), this.savedMediaPref)
          .subscribe({
              next: (isSaved: boolean) => { this.loggingService.logVerbose(successMsg); return resolve(); },
              error: () => { this.loggingService.logError(errorMsg); return reject(); },
              complete: () => { this.loggingService.logVerbose(completedMsg); }              
            });
    });
  }

  setPatientMediaData(userAction: EnumUserAction) : Promise<void> {
    let successMsg: string;
    let errorMsg: string;
    let completedMsg: string;

    switch(userAction) {
      case EnumUserAction.MediaPlay:
        successMsg = "Logged Patient Media Start";
        errorMsg = "Error logging Patient Media Start"
        completedMsg = "Completed logging Patient Media Start";
      break;
      case EnumUserAction.MediaStop:
        successMsg = "Successfully Saved Patient Media Data";
        errorMsg = "Error saving Patient Media Data";
        completedMsg = "Completed saving Patient Media Data";
      break;
      case EnumUserAction.MediaSeekStart:
        successMsg = "Logged Patient Media Seek Start";
        errorMsg = "Error logging Patient Media Seek Start";
        completedMsg = "Completed logging Patient Media Seek Start";
      break;
      case EnumUserAction.MediaSeekStop:
        successMsg = "Logged Patient Media Seek Stop";
        errorMsg = "Error logging Patient Media Seek Stop";
        completedMsg = "Completed logging Patient Media Seek Stop";
      break;
    }

    return new Promise((resolve, reject) => { 
      this.mediaService.setPatientMediaData(userAction.toString(), this.savedMediaPref)
      .subscribe({
          next: (isSaved: boolean) => {                            
                this.loggingService.logVerbose("Successfully recorded patient MediaPlay");
                return resolve();
                },
          error: () => { this.loggingService.logError("Error recording patient Media Play"); return reject(); },
          complete: () => { this.loggingService.logVerbose("Completed recording patient media play"); }          
      });
    });
  }

  public onMediaPlay(obj: {playPositionSeconds: number}) {
    // Log Media Play activity
    if (this.savedMediaPref.PlayStartPositionSeconds != obj.playPositionSeconds) {
      this.onMediaSeekEnd({seekStartPositionSeconds: this.savedMediaPref.PlayEndPositionSeconds, seekEndPositionSeconds: obj.playPositionSeconds}).then(() => {
        this.logMediaPlay(obj);
      });
    } else {
      this.logMediaPlay(obj);
    }      
  }

  logMediaPlay(obj: {playPositionSeconds: number}) {
    this.savedMediaPref.PlayStartPositionSeconds = obj.playPositionSeconds;
    if (this.savedMediaPref.PatientId) {
      this.setPatientMediaData(EnumUserAction.MediaPlay);
    } else if (this.savedMediaPref.MediaId && this.savedMediaPref.MediaId!= this.emptyGuid) {
      this.setSessionMediaData(EnumUserAction.MediaPlay);          
    } else {
      let defaultMediaData = new CustomerMediaData();
      defaultMediaData.CustomerId = this.customerId;
      defaultMediaData.MediaId = this.defaultMediaPref.MediaId;
      defaultMediaData.SessionId = this.sessionId;
      this.mediaService.setSessionMediaData(EnumUserAction.MediaPlay.toString(), defaultMediaData)
          .subscribe({
              next: (isSaved: boolean) => { this.loggingService.logVerbose("Successfully recorded default Media Play"); },
              error: () => { this.loggingService.logError("Error recording default Media Play"); },
              complete: () => { this.loggingService.logVerbose("Completed recording default Media Play"); }
          });
    }
  }

  public onMediaPause(obj: {pausePositionSeconds: number}) { 
    // save play position   
    if (this.savedMediaPref.MediaId) {
      this.savedMediaPref.PlayEndPositionSeconds = obj.pausePositionSeconds;      
      if (this.savedMediaPref.PatientId) {        
        this.setPatientMediaData(EnumUserAction.MediaPause);            
      } else {
        this.setSessionMediaData(EnumUserAction.MediaPause);            
      }
    }
  }
 
  public onMediaSeekEnd(obj: {seekStartPositionSeconds: number, seekEndPositionSeconds: number}) : Promise<void> {
    // save seek
    return new Promise<void>((resolve, reject) => { 
      if (obj.seekStartPositionSeconds < obj.seekEndPositionSeconds) {   
        if (this.savedMediaPref.MediaId) { 
          this.savedMediaPref.PlayEndPositionSeconds = obj.seekStartPositionSeconds;
          this.savedMediaPref.PlayStartPositionSeconds = obj.seekEndPositionSeconds;
          
          if (this.savedMediaPref.PatientId) {        
            return this.setPatientMediaData(EnumUserAction.MediaSeekStop);            
          } else {
            return this.setSessionMediaData(EnumUserAction.MediaSeekStop);            
          }
        }
      }
      return resolve();
    });
  }

  public onMediaPlayExit(obj: {stopPositionSeconds: number, isPasswordValidated: boolean, doTriggerMediaStop: boolean}) { 
      //if (obj.doTriggerMediaStop) { // this prevents a situaion where media is paused, and then exited during which time we do not want to generate and log a second stop event
        //this.onMediaPause({pausePositionSeconds: obj.stopPositionSeconds});
        // save play position   
      if (this.savedMediaPref.MediaId) {
        this.savedMediaPref.PlayEndPositionSeconds = obj.stopPositionSeconds;      
        if (this.savedMediaPref.PatientId) {        
          this.setPatientMediaData(EnumUserAction.MediaStop);            
        } else {
          this.setSessionMediaData(EnumUserAction.MediaStop);            
        }
      }
      //}
         
      if (this.isPatientModeEnabled && !obj.isPasswordValidated) {
       
        this.routeHelper.NavigateToLogoutPage();
      }
      else {
        this.routeHelper.RoutePage(appPageName.worklist);
      }
    
  }

}
